import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container } from "./utils"

const HighlightedText = ({ section }) => (
  <Wrapper image={section.backgroundImage ? section.backgroundImage.url : null}>
    <CustomContainer>
      <h3>{section.title}</h3>
      <div dangerouslySetInnerHTML={{ __html: section.text }} />
    </CustomContainer>
  </Wrapper>
)

const Wrapper = styled.div`
  padding: 3rem ${DesignTokens.spacing[5]};
  background: ${DesignTokens.colors.primary[500]};
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  color: ${DesignTokens.colors.white};
  @media screen and (min-width: 769px) {
    padding: 6rem ${DesignTokens.spacing[5]};
  }
  h3 {
    font-size: ${DesignTokens.fontSizes[7]};
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: ${DesignTokens.spacing[5]};
    @media screen and (min-width: 769px) {
      margin-bottom: 0;
      font-size: ${DesignTokens.fontSizes[9]};
      line-height: 1.1;
    }
  }
  p:last-child {
    margin-bottom: 0;
  }
`

const CustomContainer = styled(Container)`
  @media screen and (min-width: 769px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & > * {
      width: 48%;
    }
  }
`

export default HighlightedText
