import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Intro from "../components/intro"
import TextWithImage from "../components/textWithImage"
import HighlightedText from "../components/highlightedText"
import Footer from "../components/footer"
import Quote from "../components/quote"

const Company = ({ data: { page } }) => (
  <Layout>
    <SEO title={page.title} lang="it" />
    <Header image={page.heroImage.url}>
      <h1>{page.title}</h1>
      <h2>{page.subtitle}</h2>
    </Header>
    <Intro title={page.title} text={page.intro} />
    {page.content.map(section => (
      <section key={section.id}>
        {section.model.apiKey === "text_with_image" && (
          <TextWithImage section={section} headingDowncase />
        )}
        {section.model.apiKey === "highlighted_text" && (
          <HighlightedText section={section} />
        )}
      </section>
    ))}
    <Quote text={page.quote} />
    <Footer />
  </Layout>
)

export default Company

export const query = graphql`
  query CompanyQuery {
    page: datoCmsCompanyPage {
      title
      subtitle
      intro
      quote
      slug
      heroImage {
        url
      }
      content {
        ... on DatoCmsTextWithImage {
          id
          title
          text
          image {
            url
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsHighlightedText {
          id
          title
          text
          image {
            url
          }
          model {
            apiKey
          }
        }
      }
    }
  }
`
