import React from "react"
import styled from "styled-components"
import { Container } from "./utils"

const Quote = ({ text }) => (
  <Wrapper>
    <Container dangerouslySetInnerHTML={{ __html: text }} />
  </Wrapper>
)

const Wrapper = styled.div`
  @media screen and (min-width: 769px) {
    padding: 3rem 0;
  }
  blockquote > p {
    font-style: italic;
    line-height: 1.5;
    font-weight: 400;
  }
  p {
      font-weight: 900;
  }
`

export default Quote
